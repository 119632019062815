import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";
import { AccessDeniedComponent } from "@codman/shared/feature-static-pages";

import { dataRequestGuard } from "./data-request-guard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
    },
    {
        path: "dashboard",
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate, dataRequestGuard],
        loadChildren: () =>
            import("@codman/data-request/dashboard").then(m => m.DataRequestDashboardModule),
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: "enabledBlocking" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
