import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, Router } from "@angular/router";
import { combineLatest, defaultIfEmpty, filter, map, startWith, switchMap, tap } from "rxjs";

import { CodmanUserAuthorizationService } from "@codman/shared/data-access-authorization";
import { DataAccessAuthorizationApiService } from "@codman/shared/data-access-authorization-api";
import { SharedLocalizationSettingsService } from "@codman/shared/util-logex-framework-setup";
import { organizationIdUrlParam } from "@codman/shared/types";

const NO_ORGANIZATION = -999;

export const dataRequestGuard: CanActivateFn = (routeSnapshot: ActivatedRouteSnapshot) => {
    const organizationIdFromQueryParams = routeSnapshot.queryParamMap.get(organizationIdUrlParam);
    const authorizationService = inject(CodmanUserAuthorizationService);
    const authorizationServiceApi = inject(DataAccessAuthorizationApiService);
    const router = inject(Router);
    const localizationService = inject(SharedLocalizationSettingsService);

    return combineLatest([
        authorizationService.getOrganizationsByRegistryId(null),
        authorizationService.organizationId$.pipe(startWith(NO_ORGANIZATION)),
        authorizationService.currentOrganizationPermissions$.pipe(startWith([])),
    ]).pipe(
        filter(([organizations, organizationId]) => {
            let organization = organizations.find(o => o.cicCode === organizationId);
            if (organization != null) {
                return true;
            }

            organization = organizations.find(
                o => o.organizationId === Number(organizationIdFromQueryParams),
            );

            // if no intial organization or it is not found in valic cic codes
            // use default and do not continue the pipe
            authorizationService.setDefaultOrganization(
                organizations,
                organization && organization.cicCode
                    ? organization.cicCode?.toString()
                    : organizationIdFromQueryParams,
            );
            return false;
        }),
        tap(([organizations, organizationId, permissions]) => {
            const englishOnlyProducts = ["Product.Epsa", "Product.Icra"];
            let useEnglishAsDefault = true;
            permissions
                .filter(permission => permission.applicationInstance.includes("DataRequest"))
                .forEach(permission => {
                    if (!englishOnlyProducts.includes(permission.product)) {
                        useEnglishAsDefault = false;
                    }
                });
            if (useEnglishAsDefault) localizationService.setPreferredLanguage("en-GB");
        }),
        switchMap(([_organizations, organizationId]) =>
            authorizationServiceApi.getPermissions({
                applicationInstance: "ApplicationInstance.DataRequest.NL",
                organizationType: "Cic",
                organizationIdentifier: organizationId,
            }),
        ),
        map(permissions => permissions.length > 0),
        defaultIfEmpty(false),
        map(hasAccess => {
            if (!hasAccess) {
                return router.parseUrl("access-denied");
            }
            return hasAccess;
        }),
    );
};
